import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/mobile/icon_r_active.png';
const _withScopeId = n => (_pushScopeId("data-v-448e6232"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sec4Swiper"
};
const _hoisted_2 = {
  class: "t3"
};
const _hoisted_3 = {
  class: "ct"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "nav"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  key: 1,
  class: "moreCtx"
};
const _hoisted_9 = {
  class: "t4"
};
const _hoisted_10 = {
  class: "imgs"
};
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { ref, reactive, onActivated, onMounted, getCurrentInstance, computed } from "vue";
// siwper
import { Swiper, SwiperSlide } from "swiper/vue"; // swiper所需组件
// 这是分页器和对应方法，swiper好像在6的时候就已经分离了分页器和一些其他工具
import { Autoplay, Navigation, Pagination, A11y } from "swiper";
// 引入swiper样式，对应css 如果使用less或者css只需要把scss改为对应的即可
import { customProcessConfigList } from '@/api/index.js';
import { storeToRefs } from 'pinia';
import internationalization from '@/store/internationalization';
import { casePagingQuery } from '@/api/index.js';
export default {
  __name: 'sec4Swiper',
  setup(__props) {
    const internationalizationStore = internationalization();
    let {
      languages,
      filePrefix
    } = storeToRefs(internationalizationStore);
    const modules1 = [Autoplay, Pagination, Navigation, A11y];
    const {
      proxy
    } = getCurrentInstance();
    const navList = ref(['商务洽谈', '商务确认', '设计开发', '交付验收', '售后服务']);
    let ctrl_nav_num = ref(0);
    let myswiper = ref(null);
    const setControlledSwiper = el => myswiper = el;
    const ctrl_nav_click = i => {
      ctrl_nav_num.value = i; //并且指定跳转
      myswiper.slideTo(i, 1000, false); //同时让siwper跳转指定页面
    };

    const pre = () => {
      const preBtn = document.querySelectorAll('.swiper-button-prev')[1];
      preBtn.click();
    };
    const next = () => {
      const nextBtn = document.querySelectorAll('.swiper-button-next')[1];
      nextBtn.click();
    };
    // 左右滑动事件
    const transitionEnd = e => ctrl_nav_num.value = e.activeIndex;
    let baseUrl = ref('');
    const getList = () => {
      customProcessConfigList().then(res => {
        if (res.code == 0) {
          navList.value = res.data;
        }
      });
    };
    baseUrl = computed(() => {
      return filePrefix.value;
    });
    onMounted(() => {
      getList();
    });
    return (_ctx, _cache) => {
      const _directive_lazy = _resolveDirective("lazy");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(Swiper), {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: false,
        centeredSlides: true,
        onSwiper: setControlledSwiper,
        modules: modules1,
        class: "mySwiper",
        navigation: true,
        onTransitionEnd: transitionEnd
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navList.value, (item, index) => {
          return _openBlock(), _createBlock(_unref(SwiperSlide), {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("img", {
              class: "imgbg",
              src: _unref(baseUrl) + item.imgUri,
              alt: ""
            }, null, 8, _hoisted_4), [[_directive_lazy]])])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["default_nav", _unref(ctrl_nav_num) == index ? 'active_nav' : '']),
          onClick: $event => ctrl_nav_click(index)
        }, [_createElementVNode("div", null, "0" + _toDisplayString(index + 1), 1), _unref(languages) == 'ch' ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(item.cnProcessTitle), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(item.engProcessTitle), 1))], 10, _hoisted_6);
      }), 128))])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_withDirectives(_createElementVNode("img", {
        class: "img2",
        onClick: pre,
        src: _imports_0,
        alt: ""
      }, null, 512), [[_directive_lazy]]), _withDirectives(_createElementVNode("img", {
        class: "img4",
        onClick: next,
        src: _imports_0,
        alt: ""
      }, null, 512), [[_directive_lazy]])])])]);
    };
  }
};