import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7931b61a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "swiper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_catalogue = _resolveComponent("catalogue");
  const _component_homeOne = _resolveComponent("homeOne");
  const _component_companyProfile = _resolveComponent("companyProfile");
  const _component_caseStudy = _resolveComponent("caseStudy");
  const _component_customProcess = _resolveComponent("customProcess");
  const _component_onlineConsultation = _resolveComponent("onlineConsultation");
  return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.isMenu ? (_openBlock(), _createBlock(_component_catalogue, {
    key: 0,
    pageIndex: _ctx.pageIndex,
    onOpenMenu: _ctx.openMenu,
    onChangePageIndex: _ctx.changePageIndex
  }, null, 8, ["pageIndex", "onOpenMenu", "onChangePageIndex"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: "page1 page",
    style: _normalizeStyle({
      transform: _ctx.pageIndex <= 1 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)',
      zIndex: 5
    })
  }, [_createVNode(_component_homeOne, {
    pageIndex: _ctx.pageIndex,
    onOpenMenu: _ctx.openMenu,
    style: {
      "position": "relative"
    },
    onChangePageIndex: _ctx.changePageIndex
  }, null, 8, ["pageIndex", "onOpenMenu", "onChangePageIndex"])], 4), _createElementVNode("div", {
    class: "page2 page",
    style: _normalizeStyle({
      transform: _ctx.pageIndex <= 2 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)',
      zIndex: 4
    })
  }, [_createVNode(_component_companyProfile, {
    pageIndex: _ctx.pageIndex,
    onOpenMenu: _ctx.openMenu,
    onChangePageIndex: _ctx.changePageIndex
  }, null, 8, ["pageIndex", "onOpenMenu", "onChangePageIndex"])], 4), _createElementVNode("div", {
    class: "page3 page",
    style: _normalizeStyle({
      transform: _ctx.pageIndex <= 3 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)',
      zIndex: 3
    })
  }, [_createVNode(_component_caseStudy, {
    pageIndex: _ctx.pageIndex,
    onOpenMenu: _ctx.openMenu,
    onChangePageIndex: _ctx.changePageIndex
  }, null, 8, ["pageIndex", "onOpenMenu", "onChangePageIndex"])], 4), _createElementVNode("div", {
    class: "page4 page",
    style: _normalizeStyle({
      transform: _ctx.pageIndex <= 4 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)',
      zIndex: 2
    })
  }, [_createVNode(_component_customProcess, {
    pageIndex: _ctx.pageIndex,
    onOpenMenu: _ctx.openMenu,
    onChangePageIndex: _ctx.changePageIndex
  }, null, 8, ["pageIndex", "onOpenMenu", "onChangePageIndex"])], 4), _createElementVNode("div", {
    class: "page5 page",
    style: _normalizeStyle({
      transform: _ctx.pageIndex <= 5 ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)',
      zIndex: 1
    })
  }, [_createVNode(_component_onlineConsultation, {
    pageIndex: _ctx.pageIndex,
    onOpenMenu: _ctx.openMenu,
    onChangePageIndex: _ctx.changePageIndex
  }, null, 8, ["pageIndex", "onOpenMenu", "onChangePageIndex"])], 4)])])], 64);
}