import catalogue from '@/components/catalogue.vue';
import homeOne from '@/components/vessel/home.vue';
import companyProfile from '@/components/vessel/companyProfile.vue';
import caseStudy from '@/components/vessel/caseStudy.vue';
import customProcess from '@/components/vessel/customProcess.vue';
import onlineConsultation from '@/components/vessel/onlineConsultation.vue';
import useStore from '@/store/internationalization';
import { storeToRefs } from 'pinia';
import { defineComponent, onMounted, ref, onBeforeUnmount, defineEmits } from 'vue';
import { useRoute } from 'vue-router';
export default defineComponent({
  name: 'Home',
  components: {
    homeOne,
    companyProfile,
    caseStudy,
    catalogue,
    customProcess,
    onlineConsultation
  },
  setup() {
    const store = useStore();
    const isMenu = ref(false);
    const route = useRoute();
    let {
      pageIndex
    } = storeToRefs(store);
    // 函数防抖
    const debounce = (func, wait) => {
      let timeout;
      return function () {
        let context = this;
        let args = arguments;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    };
    // 滚轮监听
    const methodB = e => {
      console.error(e.deltaY);
      if (isMenu.value) return;
      if (e.deltaY > 0 && pageIndex.value == 5) {
        //  下一张  最后一张
        pageIndex.value = 1;
      } else if (e.deltaY > 0 && pageIndex.value != 5) {
        //  下一张    不等于最后一张
        pageIndex.value++;
      } else if (e.deltaY < 0 && pageIndex.value != 1) {
        //  上一张 不等于第一张
        pageIndex.value--;
      } else if (e.deltaY < 0 && pageIndex.value == 1) {
        //  上一张 第一张
        pageIndex.value = 1;
      } else {
        return;
      }
      console.log(pageIndex.value);
    };
    // 页面导航展示隐藏
    const openMenu = val => {
      isMenu.value = val;
    };
    const changePageIndex = index => {
      pageIndex.value = index;
      openMenu(false);
    };
    onMounted(() => {
      if (!route.params.resist) {
        //监听鼠标滚动事件
        window.addEventListener('mousewheel', debounce(methodB, 300), true) || window.addEventListener("DOMMouseScroll", debounce(methodB, 300), false);
      }
      window.addEventListener("popstate", function (e) {
        location.reload();
      }, false);
    });
    return {
      isMenu,
      pageIndex,
      openMenu,
      changePageIndex
    };
  }
});