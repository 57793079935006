import request from '@/utils/request'
// 获取文件前缀

// 首页配置查询
export function queryHomeConfig(data) {
	return request({
		url: '/home/config/queryHomeConfig',
		method: 'post',
		data
	})
}

// 查询公司详情 
export function queryCompanyProfileConfig(data) {
	return request({
		url: '/company/profile/config/queryCompanyProfileConfig',
		method: 'post',
		data
	})
}

//案例分析 列表查询 /case/analysis/casePagingQuery
export function casePagingQuery(data) {
	return request({
		url: '/case/analysis/casePagingQuery',
		method: 'post',
		data
	})
}

// /case/analysis/caseAnalysisDetails
export function caseAnalysisDetails(data) {
	return request({
		url: '/case/analysis/caseAnalysisDetails',
		method: 'post',
		data
	})
}

// 定制流程 /custom/process/config/customProcessConfigList
export function customProcessConfigList(data) {
	return request({
		url: '/custom/process/config/customProcessConfigList',
		method: 'post',
		data
	})
}
// 定制流程详情
export function customProcessConfigDetails(data) {
	return request({
		url: '/custom/process/config/customProcessConfigDetails',
		method: 'post',
		data
	})
}

// 定制流程详情
export function addOnlineConsulting(data) {
	return request({
		url: '/online/consulting/addOnlineConsulting',
		method: 'post',
		data
	})
}