export default{
    message: {
        name: '姓名',
        companyname: '芸享',
        content: '在线留言',
        videohint: '您的浏览器不支持视频标记。',
        clientNum: '客户数量已经超过',
        contactus: '联系我们',
        home: '首页',
        phone: '电话',
        weChat: '微信',
        email: '邮箱',
        errMsg: {
            inputRequired: '请输入{cont}',
            selectRequired: '请选择{cont}'  
        },
        aaa: '芸享 . 想你所想',
        online: '24h在线留言',
        yourNeedsText: '留下您的需求,我们会有专业人员立即跟您联系~',
        follow: '扫一扫，加个人微信',
        confirm: '确认提交',
        mobileSec3_1:'全部',
        mobileSec3_2:'移动端开发',
        mobileSec3_3:'网页开发',
        mobileSec3_4:'其他开发',
        noDate:'暂无数据',
        cpnMenu_1:'首页',
        cpnMenu_2:'公司简介',
        cpnMenu_3_1:'APP开发',
        cpnMenu_3_2:'网页开发',
        cpnMenu_3_3:'系统开发',
        cpnMenu_3_4:'其他开发',
        cpnMenu_4:'定制流程',
        cpnMenu_5:'在线留言',
        location:'您的位置',
        caseAnalysis:'案例分析',
        details:'详情',
        placeholderName:'请输入您的称呼',
        placeholderPhone:'请输入您的电话',
        placeholderEmail:'请输入您的微信/邮箱',
        placeholderContent:'告诉我们您的想法,我们可以帮您解决什么问题',
    },
}