import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/caseStudy/page.png';
import _imports_1 from '@/assets/common/logoName.png';
import _imports_2 from '@/assets/caseStudy/sort2.png';
import _imports_3 from '@/assets/caseStudy/changeL.png';
import _imports_4 from '@/assets/caseStudy/changeR.png';
import _imports_5 from '@/assets/caseStudy/sort.png';
import _imports_6 from '@/assets/caseStudy/sort1.png';
const _withScopeId = n => (_pushScopeId("data-v-7cc9c465"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "caseStudy"
};
const _hoisted_2 = {
  class: "page",
  src: _imports_0,
  alt: ""
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  key: 0,
  class: "menu"
};
const _hoisted_5 = {
  class: "logo animate__animated animate__rubberBand animate__slow",
  src: _imports_1,
  alt: ""
};
const _hoisted_6 = {
  class: "title"
};
const _hoisted_7 = {
  class: "left"
};
const _hoisted_8 = {
  class: "icon",
  src: _imports_2,
  alt: ""
};
const _hoisted_9 = {
  class: "right"
};
const _hoisted_10 = ["onClick"];
const _hoisted_11 = {
  class: "itemsT"
};
const _hoisted_12 = {
  class: "itemsT"
};
const _hoisted_13 = ["src"];
const _hoisted_14 = {
  style: {
    "height": "10px"
  }
};
const _hoisted_15 = {
  key: 0,
  class: "itemsC"
};
const _hoisted_16 = {
  key: 1,
  class: "itemsC"
};
const _hoisted_17 = ["onClick"];
const _hoisted_18 = ["onClick"];
const _hoisted_19 = {
  class: "footer"
};
const _hoisted_20 = {
  class: "",
  style: {
    "width": "45%"
  }
};
const _hoisted_21 = {
  class: "sort animate__animated animate__backInLeft animate__slow",
  src: _imports_5,
  alt: ""
};
const _hoisted_22 = {
  class: "tab animate__animated animate__backInRight animate__slow"
};
const _hoisted_23 = {
  class: "icon",
  src: _imports_6,
  alt: ""
};
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/vue"; // swiper所需组件
import { Autoplay, Navigation, Pagination, A11y } from "swiper";
import pcMenu from '@/components/pcMenu.vue';
import headToggle from '@/views/home/components/cpn/headToggle.vue';
import internationalization from '@/store/internationalization';
import { casePagingQuery } from '@/api/index.js';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { ref, watchEffect, onMounted, reactive, computed } from 'vue';
export default {
  __name: 'caseStudy',
  props: {
    pageIndex: {
      type: Number,
      require: true
    }
  },
  emits: ["openMenu", 'pcMenuPage', 'changePageIndex'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const internationalizationStore = internationalization();
    let myswiper = ref(null);
    const setControlledSwiper = el => myswiper = el;
    const {
      languages,
      filePrefix
    } = storeToRefs(internationalizationStore);
    const router = useRouter();
    let currentIndex = ref('');
    let baseUrl = ref('');
    let dataList = ref([]);
    let isCaseStudyView = ref(false);
    const openMenu = () => {
      emit("openMenu", true);
    };
    const openMenu2 = () => {
      emit("openMenu", true);
    };
    const mouseover = val => {
      //移进
      currentIndex.value = val;
      clearInterval(slideshowTimer.value);
      page.value = val + 1;
    };
    const mouseLeave = () => {
      //移出
      currentIndex.value = null;
      slideshowTimer.value = setInterval(() => {
        page.value++;
        if (page.value >= dataList.value.length) page.value = 2;
        myswiper.slideTo(page.value, 1000, false);
      }, 3000);
    };
    const view = id => {
      //查看详情
      isCaseStudyView.value = true;
      router.push({
        path: '/caseDtl',
        query: {
          id
        }
      });
    };
    const jump = caseType => {
      router.push({
        path: '/caseStudyView',
        query: {
          caseType
        }
      });
    };
    const pcMenuPage = parmas => {
      emit("changePageIndex", parmas.index);
    };
    const handelDialog = e => {
      isCaseStudyView.value = false;
    };
    const turnL = () => {
      //左转
      page.value--;
      if (page.value <= 2) page.value = 2;
      myswiper.slideTo(page.value, 1000, false);
    };
    const turnR = () => {
      //右转
      page.value++;
      if (page.value >= dataList.value.length) page.value = 2;
      myswiper.slideTo(page.value, 1000, false);
    };
    const hide = () => {
      isCaseStudyView.value = false;
    };
    let timer = ref(null);
    let bool = ref(true);
    watchEffect(() => {
      if (props.pageIndex != 3) {
        timer = setTimeout(() => {
          bool.value = false;
        }, 1000);
      } else {
        if (timer) clearTimeout(timer);
        bool.value = true;
      }
    });
    const slideshowTimer = ref(null); // 幻灯片轮播
    const getList = () => {
      casePagingQuery({
        page: 1,
        limit: 9999,
        caseTpye: ''
      }).then(res => {
        if (res.code == 0) {
          dataList.value = res.data;
          myswiper.slideTo(page.value, 1000, false);
          slideshowTimer.value = setInterval(() => {
            page.value++;
            if (page.value >= dataList.value.length) page.value = 2;
            myswiper.slideTo(page.value, 1000, false);
          }, 3000);
        }
      });
    };
    const goAllCase = () => {
      router.push({
        path: '/caseStudyView',
        query: {
          caseType: ''
        }
      });
    };
    // 移动端 导航栏变化
    const isPc = ref(true);
    const page = ref(2);
    baseUrl = computed(() => {
      return filePrefix.value;
    });
    onMounted(() => {
      isPc.value = sessionStorage.getItem('isPc') == 'true' ? true : false;
      getList();
    });
    const anchorPoint = i => {
      router.push({
        path: '/',
        query: {
          id: i
        }
      });
    };
    return (_ctx, _cache) => {
      const _directive_lazy = _resolveDirective("lazy");
      return _unref(bool) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [[_directive_lazy]]), _createElementVNode("div", _hoisted_3, [isPc.value ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [[_directive_lazy]]), _createVNode(pcMenu, {
        onPcMenuPage: pcMenuPage
      })])) : _createCommentVNode("", true), !isPc.value ? (_openBlock(), _createBlock(headToggle, {
        key: 1,
        onAnchorPoint: anchorPoint
      })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_withDirectives(_createElementVNode("img", _hoisted_8, null, 512), [[_directive_lazy]])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("span", {
        onClick: goAllCase
      }, _toDisplayString(_ctx.$t(`message.mobileSec3_1`)) + " >", 1)])]), _createVNode(_unref(Swiper), {
        class: "content swiper-no-swiping",
        slidesPerView: 5,
        spaceBetween: 2,
        loop: false,
        centeredSlides: true,
        modules: _ctx.modules1,
        navigation: false,
        onSwiper: setControlledSwiper,
        onTransitionEnd: _ctx.transitionEnd
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataList), (item, index) => {
          return _openBlock(), _createBlock(_unref(SwiperSlide), {
            class: "items",
            onMouseenter: $event => mouseover(index),
            onMouseleave: $event => mouseLeave(index),
            key: item.caseAnalysisId
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              onClick: $event => view(item.caseAnalysisId)
            }, [_createElementVNode("div", _hoisted_11, _toDisplayString(item.cnTitle), 1), _createElementVNode("div", _hoisted_12, _toDisplayString(item.engTitle), 1), _withDirectives(_createElementVNode("img", {
              src: _unref(baseUrl) + item.imgUri,
              alt: ""
            }, null, 8, _hoisted_13), [[_directive_lazy], [_vShow, _unref(currentIndex) == index]]), _withDirectives(_createElementVNode("div", _hoisted_14, null, 512), [[_vShow, _unref(currentIndex) != index]]), _unref(languages) == 'ch' ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(item.cnContent), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(item.engContent), 1))], 8, _hoisted_10)]),
            _: 2
          }, 1032, ["onMouseenter", "onMouseleave"]);
        }), 128))]),
        _: 1
      }, 8, ["modules", "onTransitionEnd"]), _withDirectives(_createElementVNode("img", {
        class: "cant",
        src: _imports_3,
        alt: "",
        onClick: _withModifiers(turnL, ["stop"])
      }, null, 8, _hoisted_17), [[_directive_lazy]]), _withDirectives(_createElementVNode("img", {
        class: "can",
        src: _imports_4,
        alt: "",
        onClick: _withModifiers(turnR, ["stop"])
      }, null, 8, _hoisted_18), [[_directive_lazy]]), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_withDirectives(_createElementVNode("img", _hoisted_21, null, 512), [[_directive_lazy]])]), _createElementVNode("div", _hoisted_22, [_withDirectives(_createElementVNode("img", _hoisted_23, null, 512), [[_directive_lazy]])])])])])) : _createCommentVNode("", true);
    };
  }
};