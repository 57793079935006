import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-71032e46"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pcMenu"
};
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { inject, onMounted } from 'vue';
import internationalization from '@/store/internationalization';
export default {
  __name: 'pcMenu',
  props: {
    isPc: {
      type: Boolean,
      require: true
    }
  },
  emits: ["pcMenuPage"],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const internationalizationStore = internationalization();
    const {
      languages
    } = storeToRefs(internationalizationStore);
    const router = useRouter();
    const reload = inject('reload');
    const changePage = (index, str = '') => {
      if (index != "3") {
        emit("pcMenuPage", {
          index
        });
      } else {
        if (str == 'APP开发') {
          router.push({
            path: '/caseStudyView',
            query: {
              caseType: '1'
            }
          });
        } else if (str == '网页开发') {
          router.push({
            path: '/caseStudyView',
            query: {
              caseType: '2'
            }
          });
        } else if (str == '系统开发') {
          router.push({
            path: '/caseStudyView',
            query: {
              caseType: '3'
            }
          });
        } else {
          router.push({
            path: '/caseStudyView',
            query: {
              caseType: ''
            }
          });
        }
      }
    };
    const app_script = url => {
      let scriptsArr = document.getElementsByTagName('script');
      let newArr = [];
      //获取所有的script地址
      for (var i = 0; i < scriptsArr.length; i++) {
        newArr.push(scriptsArr[i].getAttribute('src', 4));
      }
      let isFirst = true; //判断是否加载过这个script，有就不加载了
      let cdnUrl = url;
      newArr.forEach(item => {
        if (item === cdnUrl) {
          isFirst = false;
        }
      });
      if (isFirst) {
        // 创建script标签，引入外部文件
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        document.getElementById('app').appendChild(script);
      }
    };
    onMounted(() => {
      // if(languages.value == 'ch') {
      //     app_script('https://dbt.zoosnet.net/JS/LsJS.aspx?siteid=DBT74837949&float=1&lng=cn');
      // }else {
      //     app_script('https://dbt.zoosnet.net/JS/LsJS.aspx?siteid=DBT74837949&float=1&lng=en');
      // }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = $event => changePage(1, ''))
      }, _toDisplayString(_ctx.$t(`message.cpnMenu_1`)), 1), _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = $event => changePage(2, ''))
      }, _toDisplayString(_ctx.$t(`message.cpnMenu_2`)), 1), _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = $event => changePage(3, 'APP开发'))
      }, _toDisplayString(_ctx.$t(`message.cpnMenu_3_1`)), 1), _createElementVNode("div", {
        onClick: _cache[3] || (_cache[3] = $event => changePage(3, '网页开发'))
      }, _toDisplayString(_ctx.$t(`message.cpnMenu_3_2`)), 1), _createElementVNode("div", {
        onClick: _cache[4] || (_cache[4] = $event => changePage(3, '系统开发'))
      }, _toDisplayString(_ctx.$t(`message.cpnMenu_3_3`)), 1), _createElementVNode("div", {
        onClick: _cache[5] || (_cache[5] = $event => changePage(3, '其他开发'))
      }, _toDisplayString(_ctx.$t(`message.cpnMenu_3_4`)), 1), _createElementVNode("div", {
        onClick: _cache[6] || (_cache[6] = $event => changePage(4, ''))
      }, _toDisplayString(_ctx.$t(`message.cpnMenu_4`)), 1), _createElementVNode("div", {
        class: "last",
        onClick: _cache[7] || (_cache[7] = $event => changePage(5, ''))
      }, _toDisplayString(_ctx.$t(`message.cpnMenu_5`)), 1)]);
    };
  }
};