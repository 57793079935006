import { unref as _unref, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/onlineConsultation/page.png';
import _imports_1 from '@/assets/common/logoName.png';
import _imports_2 from '@/assets/onlineConsultation/sort2.png';
import _imports_3 from '@/assets/common/phone.png';
import _imports_4 from '@/assets/common/weChat.png';
import _imports_5 from '@/assets/common/mail.png';
import _imports_6 from '@/assets/mobile/qrcode.jpg';
import _imports_7 from '@/assets/onlineConsultation/sort.png';
const _withScopeId = n => (_pushScopeId("data-v-0f3230d1"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "onlineConsultation"
};
const _hoisted_2 = {
  class: "page",
  src: _imports_0,
  alt: ""
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  class: "menu"
};
const _hoisted_5 = {
  class: "logo animate__animated animate__rubberBand animate__slow",
  src: _imports_1,
  alt: ""
};
const _hoisted_6 = {
  class: "title"
};
const _hoisted_7 = {
  class: "icon",
  src: _imports_2,
  alt: ""
};
const _hoisted_8 = {
  class: "content"
};
const _hoisted_9 = {
  class: "animate__animated animate__zoomInDown animate__slow"
};
const _hoisted_10 = {
  class: "text animate__animated animate__zoomInUp animate__slow"
};
const _hoisted_11 = {
  class: "contact animate__animated animate__zoomInUp animate__slow"
};
const _hoisted_12 = {
  src: _imports_3,
  alt: ""
};
const _hoisted_13 = {
  src: _imports_4,
  alt: ""
};
const _hoisted_14 = {
  src: _imports_5,
  alt: ""
};
const _hoisted_15 = {
  class: "right animate__animated animate__backInRight animate__slow"
};
const _hoisted_16 = {
  class: "qrcode"
};
const _hoisted_17 = {
  src: _imports_6,
  alt: ""
};
const _hoisted_18 = {
  class: "form animate__animated animate__zoomInDown animate__slow"
};
const _hoisted_19 = {
  class: "row1"
};
const _hoisted_20 = {
  class: "row2"
};
const _hoisted_21 = {
  class: "btn"
};
const _hoisted_22 = {
  class: "footer"
};
const _hoisted_23 = {
  class: "",
  style: {
    "width": "45%"
  }
};
const _hoisted_24 = {
  class: "sort animate__animated animate__backInLeft animate__slow",
  src: _imports_7,
  alt: ""
};
import languageSwitch from '@/components/languageSwitch.vue';
import pcMenu from '@/components/pcMenu.vue';
import { addOnlineConsulting } from '@/api/index.js';
import { storeToRefs } from 'pinia';
import { ref, watchEffect, reactive, toRefs, getCurrentInstance } from 'vue';
import internationalization from '@/store/internationalization';
export default {
  __name: 'onlineConsultation',
  props: {
    pageIndex: {
      type: Number,
      require: true
    }
  },
  emits: ["openMenu", 'pcMenuPage', 'changePageIndex'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const internationalizationStore = internationalization();
    let {
      languages
    } = storeToRefs(internationalizationStore);
    const {
      proxy,
      appContext: {
        config: {
          globalProperties
        }
      }
    } = getCurrentInstance();
    const openMenu = () => {
      emit("openMenu", true);
    };
    const pcMenuPage = parmas => {
      emit("changePageIndex", parmas.index);
    };
    let timer = ref(null);
    let bool = ref(true);
    watchEffect(() => {
      if (props.pageIndex != 5) {
        timer = setTimeout(() => {
          bool.value = false;
        }, 1000);
      } else {
        if (timer) clearTimeout(timer);
        bool.value = true;
      }
    });
    // 表单
    const formRef = ref(null);
    const form = reactive({
      name: '',
      phone: '',
      email: '',
      content: ''
    });
    const rules = reactive({
      name: [{
        required: true,
        message: globalProperties.$t(`message.placeholderName`),
        trigger: 'blur'
      }],
      phone: [{
        required: true,
        message: globalProperties.$t(`message.placeholderPhone`),
        trigger: 'blur'
      }],
      email: [{
        required: true,
        message: globalProperties.$t(`message.placeholderEmail`),
        trigger: 'blur'
      }],
      content: [{
        required: true,
        message: globalProperties.$t(`message.placeholderContent`),
        trigger: 'blur'
      }]
    });
    const onSubmit = formEl => {
      formRef.value.validate(async valid => {
        if (valid) {
          addOnlineConsulting(form).then(res => {
            if (res) {
              proxy.$message({
                type: "success",
                message: "提交成功，稍后会有客服联系您！"
              });
              formEl.resetFields();
            }
          });
        } else {
          return false;
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _directive_lazy = _resolveDirective("lazy");
      return _unref(bool) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [[_directive_lazy]]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [[_directive_lazy]]), _createVNode(pcMenu, {
        onPcMenuPage: pcMenuPage
      })]), _createElementVNode("div", _hoisted_6, [_withDirectives(_createElementVNode("img", _hoisted_7, null, 512), [[_directive_lazy]])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t(`message.online`)), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t(`message.yourNeedsText`)), 1), _createElementVNode("div", _hoisted_11, [_createElementVNode("span", null, [_withDirectives(_createElementVNode("img", _hoisted_12, null, 512), [[_directive_lazy]]), _createTextVNode(_toDisplayString(_ctx.$t(`message.phone`)) + " " + _toDisplayString(_unref(internationalizationStore).tel), 1)]), _createElementVNode("span", null, [_withDirectives(_createElementVNode("img", _hoisted_13, null, 512), [[_directive_lazy]]), _createTextVNode(_toDisplayString(_ctx.$t(`message.weChat`)) + " " + _toDisplayString(_unref(internationalizationStore).weChat), 1)]), _createElementVNode("span", null, [_withDirectives(_createElementVNode("img", _hoisted_14, null, 512), [[_directive_lazy]]), _createTextVNode(_toDisplayString(_ctx.$t(`message.email`)) + " " + _toDisplayString(_unref(internationalizationStore).email), 1)])])]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_withDirectives(_createElementVNode("img", _hoisted_17, null, 512), [[_directive_lazy]]), _createElementVNode("p", null, _toDisplayString(_ctx.$t(`message.follow`)), 1)])])]), _createElementVNode("div", _hoisted_18, [_createVNode(_component_el_form, {
        model: form,
        ref_key: "formRef",
        ref: formRef,
        inline: true,
        rules: rules,
        "label-width": "80px",
        "label-position": "left"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_19, [_createVNode(_component_el_form_item, {
          prop: "name",
          label: _ctx.$t(`message.name`)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            id: "name",
            class: "wid",
            modelValue: form.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.name = $event),
            placeholder: _ctx.$t(`message.placeholderName`),
            "prefix-icon": _ctx.User,
            clearable: ""
          }, null, 8, ["modelValue", "placeholder", "prefix-icon"])]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_form_item, {
          prop: "phone",
          label: _ctx.$t(`message.phone`)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            id: "phone",
            class: "wid",
            modelValue: form.phone,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.phone = $event),
            placeholder: _ctx.$t(`message.placeholderPhone`),
            "prefix-icon": _ctx.Iphone,
            clearable: ""
          }, null, 8, ["modelValue", "placeholder", "prefix-icon"])]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_form_item, {
          prop: "email",
          label: _ctx.$t(`message.email`)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            id: "email",
            class: "wid",
            modelValue: form.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form.email = $event),
            placeholder: _ctx.$t(`message.placeholderEmail`),
            "prefix-icon": _ctx.Message,
            clearable: ""
          }, null, 8, ["modelValue", "placeholder", "prefix-icon"])]),
          _: 1
        }, 8, ["label"])]), _createElementVNode("div", _hoisted_20, [_createVNode(_component_el_form_item, {
          prop: "content",
          label: _ctx.$t(`message.content`)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            id: "content",
            class: "wid",
            rows: "4",
            modelValue: form.content,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form.content = $event),
            placeholder: _ctx.$t(`message.placeholderContent`),
            type: "text"
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }, 8, ["label"])]), _createElementVNode("div", _hoisted_21, [_createVNode(_component_el_button, {
          type: "primary",
          class: "btn-submit",
          onClick: _cache[4] || (_cache[4] = $event => onSubmit(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t(`message.confirm`)), 1)]),
          _: 1
        })])]),
        _: 1
      }, 8, ["model", "rules"])]), _createElementVNode("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, [_withDirectives(_createElementVNode("img", _hoisted_24, null, 512), [[_directive_lazy]])])])])])) : _createCommentVNode("", true);
    };
  }
};