import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a83abbcc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "t1"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "right"
};
const _hoisted_4 = {
  class: "row1"
};
const _hoisted_5 = {
  class: "row2"
};
export default {
  __name: 'itemTitle',
  props: {
    num: String,
    companyName: String,
    enName: String
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(__props.num), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(__props.companyName), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(__props.enName), 1)])]);
    };
  }
};