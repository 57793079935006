export default{
    message: {
        name: 'name',
        companyname: 'Yun xiang',
        content: 'online message',
        videohint: 'Your browser does not support the video tag.',
        clientNum: 'The number of customers has exceeded',
        contactus: 'contact us',
        home: 'home',
        phone: 'phone',
        weChat: 'weChat',
        email: 'email',
        errMsg: {
            inputRequired: 'Please Input {cont}',
            selectRequired: 'Please Select {cont}'
        },
        aaa: 'Yunxiang . Think what you want',
        online: '24h online message',
        yourNeedsText: 'Leave your needs and we will have professionals to contact you immediately~',
        follow: 'Scan and add personal wechat',
        confirm: 'Confirm',
        mobileSec3_1:'All',
        mobileSec3_2:'Mobile',
        mobileSec3_3:'Web',
        mobileSec3_4:'Other',
        noDate:'NO Data',
        cpnMenu_1:'Home',
        cpnMenu_2:'CompanyProfile',
        cpnMenu_3_1:'APP',
        cpnMenu_3_2:'Web',
        cpnMenu_3_3:'System',
        cpnMenu_3_4:'Other',
        cpnMenu_4:'CustomProcess',
        cpnMenu_5:'Online message',
        location:'location',
        caseAnalysis:'case Analysis  ',
        details:'detailes',
        placeholderName:'Please enter your name',
        placeholderPhone:'Please enter your phone number',
        placeholderEmail:'Please enter your wechat/email address',
        placeholderContent:'Tell us what you think, what problems can we help you solve',

    },
}