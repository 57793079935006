import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import axios from 'axios';
import pcHome from './components/pcHome.vue';
import mobileHome from './components/mobileHome.vue';
import { ref, onMounted } from 'vue';
import { queryHomeConfig } from '@/api/index.js';
import { storeToRefs } from 'pinia';
import internationalization from '@/store/internationalization';
export default {
  __name: 'Home',
  setup(__props) {
    const internationalizationStore = internationalization();
    let {
      chCompanyProfile,
      enCompanyProfile,
      tel,
      email,
      weChat,
      languages,
      filePrefix
    } = storeToRefs(internationalizationStore);
    const isPc = ref(true);
    // true => PC；false => 移动端
    const IsPC = () => {
      var userAgentInfo = navigator.userAgent;
      var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      var flagPc = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flagPc = false;
          break;
        }
      }
      // 更换商务通图片
      // setTimeout(() => {
      //     const ele = document.getElementById("LRfloater0");
      //     const img = ele.getElementsByTagName("img")[0];
      //     ele.style.display = 'block';
      //     if(isPc.value) {
      //       img.src = require("@/assets/service.gif");
      //       img.style.width = '300px';
      //     }else {
      //       img.src = require("@/assets/service.png");
      //     }
      // }, 1500)
      return flagPc;
    };
    const getInfo = () => {
      queryHomeConfig({
        type: '1'
      }).then(res => {
        if (res.code == 0) {
          internationalizationStore.$patch({
            chCompanyProfile: res.data.chineseValue,
            enCompanyProfile: res.data.englishValue
          });
        }
      });
      queryHomeConfig({
        type: '2'
      }).then(res => {
        if (res.code == 0) {
          internationalizationStore.$patch({
            tel: res.data.chineseValue
          });
        }
      });
      queryHomeConfig({
        type: '3'
      }).then(res => {
        if (res.code == 0) {
          internationalizationStore.$patch({
            weChat: res.data.chineseValue
          });
        }
      });
      queryHomeConfig({
        type: '4'
      }).then(res => {
        if (res.code == 0) {
          internationalizationStore.$patch({
            email: res.data.chineseValue
          });
        }
      });
    };
    onMounted(() => {
      isPc.value = IsPC();
      sessionStorage.setItem('isPc', isPc.value);
      //获取文件前缀
      axios.post("/commonApi/common/getBaseUploadUrl", {}).then(res => {
        if (res.data.code == 0) {
          const {
            baseUploadUrl
          } = res.data.data;
          internationalizationStore.$patch({
            filePrefix: baseUploadUrl
          });
        }
      });
      getInfo();
    });
    return (_ctx, _cache) => {
      return isPc.value ? (_openBlock(), _createBlock(pcHome, {
        key: 0,
        isPc: isPc.value
      }, null, 8, ["isPc"])) : (_openBlock(), _createBlock(mobileHome, {
        key: 1
      }));
    };
  }
};