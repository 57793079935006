import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from "./router";
import pinia from './store'
import '@/assets/style/normalize.css'; //清除浏览器默认样式
import i18n from './lang/i18n'
import 'animate.css';
import 'element-plus/dist/index.css';//
import 'video.js/dist/video-js.css'; // video.js样式


const app = createApp(App)
 
app.directive('lazy', {
    mounted(el) {
        let oldSrc = el.src //保存旧的src，方便后期渲染时候赋值src真实路径
        el.src = "" //将渲染的src赋为空，则不会渲染图片出来
        let observer = new IntersectionObserver(([{ isIntersecting }]) => { // 调用方法得到该elDOM元素是否处于可视区域
          if (isIntersecting) { //回调是否处于可视区域，true or false
            el.src = oldSrc //如果处于可视区域额，将最开始保存的真实路径赋予DOM元素渲染
            observer.unobserve(el) // 只需要监听一次即可，第二次滑动到可视区域时候不在监听
          }
        })
        observer.observe(el) // 调用方法
    }
})
app.use(pinia).use(ElementPlus).use(router).use(i18n).mount("#app");