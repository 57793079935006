import { unref as _unref, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, toDisplayString as _toDisplayString, renderList as _renderList, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/customProcess/page.png';
import _imports_1 from '@/assets/common/logoName.png';
import _imports_2 from '@/assets/customProcess/sort2.png';
import _imports_3 from '@/assets/customProcess/sort.png';
import _imports_4 from '@/assets/customProcess/bg_1.png';
import _imports_5 from '@/assets/customProcess/acitve_right.png';
import _imports_6 from '@/assets/caseStudy/changeL.png';
import _imports_7 from '@/assets/caseStudy/changeR.png';
const _withScopeId = n => (_pushScopeId("data-v-0bac51fa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "customProcess"
};
const _hoisted_2 = {
  class: "page",
  src: _imports_0,
  alt: ""
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  class: "menu"
};
const _hoisted_5 = {
  class: "logo animate__animated animate__rubberBand animate__slow",
  src: _imports_1,
  alt: ""
};
const _hoisted_6 = {
  class: "title"
};
const _hoisted_7 = {
  class: "icon",
  src: _imports_2,
  alt: ""
};
const _hoisted_8 = {
  class: "footer"
};
const _hoisted_9 = {
  class: "",
  style: {
    "width": "45%"
  }
};
const _hoisted_10 = {
  class: "sort animate__animated animate__backInLeft animate__slow",
  src: _imports_3,
  alt: ""
};
const _hoisted_11 = {
  class: "text animate__animated animate__zoomInUp animate__slow"
};
const _hoisted_12 = ["innerHTML"];
const _hoisted_13 = ["innerHTML"];
const _hoisted_14 = {
  key: 0,
  class: "tab animate__animated animate__backInRight animate__slow"
};
const _hoisted_15 = {
  key: 0
};
const _hoisted_16 = {
  key: 1
};
const _hoisted_17 = {
  class: "sec4Swiper"
};
const _hoisted_18 = {
  class: "t3"
};
const _hoisted_19 = {
  class: "ct"
};
const _hoisted_20 = ["src"];
const _hoisted_21 = {
  key: 1,
  class: "imgbg",
  src: _imports_4,
  alt: ""
};
const _hoisted_22 = {
  class: "nav animate__animated animate__zoomInDown animate__slow"
};
const _hoisted_23 = {
  class: "left"
};
const _hoisted_24 = ["onClick"];
const _hoisted_25 = {
  class: "active_num"
};
const _hoisted_26 = {
  key: 0
};
const _hoisted_27 = {
  key: 0,
  class: "active_img",
  src: _imports_5,
  alt: ""
};
const _hoisted_28 = {
  class: "right"
};
import languageSwitch from '@/components/languageSwitch.vue';
import pcMenu from '@/components/pcMenu.vue';
import { ref, watchEffect, getCurrentInstance, onMounted, computed } from 'vue';
import { customProcessConfigList } from '@/api/index.js';
// siwper
import { Swiper, SwiperSlide } from "swiper/vue"; // swiper所需组件
// 这是分页器和对应方法，swiper好像在6的时候就已经分离了分页器和一些其他工具
import { Autoplay, Navigation, Pagination, A11y } from "swiper";
// 引入swiper样式，对应css 如果使用less或者css只需要把scss改为对应的即可
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { storeToRefs } from 'pinia';
import internationalization from '@/store/internationalization';
export default {
  __name: 'customProcess',
  props: {
    pageIndex: {
      type: Number,
      require: true
    }
  },
  emits: ["openMenu", 'pcMenuPage', 'changePageIndex'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const internationalizationStore = internationalization();
    let {
      languages,
      filePrefix
    } = storeToRefs(internationalizationStore);
    const openMenu = () => {
      emit("openMenu", true);
    };
    const pcMenuPage = parmas => {
      emit("changePageIndex", parmas.index);
    };
    let timer = ref(null);
    let bool = ref(true);
    watchEffect(() => {
      if (props.pageIndex != 4) {
        timer = setTimeout(() => {
          bool.value = false;
        }, 1000);
      } else {
        if (timer) clearTimeout(timer);
        bool.value = true;
      }
    });

    // 关于左右滑块的
    const modules1 = [Autoplay, Pagination, Navigation, A11y];
    const {
      proxy
    } = getCurrentInstance();
    const navList = ref([]);
    let baseUrl = ref('');
    let ctrl_nav_num = ref(0);
    let myswiper = ref(null);
    const setControlledSwiper = el => myswiper = el;
    const ctrl_nav_click = i => {
      ctrl_nav_num.value = i; //并且指定跳转
      myswiper.slideTo(i, 1000, false); //同时让siwper跳转指定页面
    };

    const preIndex = ref(0);
    const nextIndex = ref(0);
    const pre = i => {
      preIndex.value = i;
      const preBtn = document.querySelector('.swiper-button-prev');
      preBtn.click();
    };
    const next = i => {
      nextIndex.value = i;
      const nextBtn = document.querySelector('.swiper-button-next');
      nextBtn.click();
    };
    // 左右滑动事件
    const transitionEnd = e => ctrl_nav_num.value = e.activeIndex;
    const getList = () => {
      customProcessConfigList().then(res => {
        if (res.code == 0) {
          navList.value = res.data;
        }
      });
    };
    baseUrl = computed(() => {
      return filePrefix.value;
    });
    onMounted(() => {
      getList();
    });
    return (_ctx, _cache) => {
      const _directive_lazy = _resolveDirective("lazy");
      return _unref(bool) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [[_directive_lazy]]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [[_directive_lazy]]), _createVNode(pcMenu, {
        onPcMenuPage: pcMenuPage
      })]), _createElementVNode("div", _hoisted_6, [_withDirectives(_createElementVNode("img", _hoisted_7, null, 512), [[_directive_lazy]])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_withDirectives(_createElementVNode("img", _hoisted_10, null, 512), [[_directive_lazy]]), _createElementVNode("div", _hoisted_11, [navList.value[_unref(ctrl_nav_num)] ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_unref(languages) == 'ch' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        innerHTML: navList.value[_unref(ctrl_nav_num)].cnProcessContent
      }, null, 8, _hoisted_12)) : (_openBlock(), _createElementBlock("div", {
        key: 1,
        innerHTML: navList.value[_unref(ctrl_nav_num)].engProcessContent
      }, null, 8, _hoisted_13))], 64)) : _createCommentVNode("", true)])]), navList.value[_unref(ctrl_nav_num)] ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_unref(languages) == 'ch' ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(navList.value[_unref(ctrl_nav_num)].cnProcessTitle), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(navList.value[_unref(ctrl_nav_num)].engProcessTitle), 1))])) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createVNode(_unref(Swiper), {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: false,
        centeredSlides: true,
        onSwiper: setControlledSwiper,
        modules: modules1,
        class: "mySwiper",
        navigation: true,
        onTransitionEnd: transitionEnd
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navList.value, (item, index) => {
          return _openBlock(), _createBlock(_unref(SwiperSlide), {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_19, [navList.value[_unref(ctrl_nav_num)] ? _withDirectives((_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "imgbg",
              src: _unref(baseUrl) + item.imgUri,
              alt: ""
            }, null, 8, _hoisted_20)), [[_directive_lazy]]) : _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_21, null, 512)), [[_directive_lazy]])])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })]), _createElementVNode("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["default_nav", _unref(ctrl_nav_num) == index ? 'active_nav' : '']),
          onClick: $event => ctrl_nav_click(index)
        }, [_createElementVNode("div", _hoisted_25, "0" + _toDisplayString(index + 1), 1), _createElementVNode("div", null, [_createElementVNode("div", null, _toDisplayString(item.cnProcessTitle), 1), _unref(ctrl_nav_num) == index ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(item.engProcessTitle), 1)) : _createCommentVNode("", true)]), _unref(ctrl_nav_num) == index ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_27, null, 512)), [[_directive_lazy]]) : _createCommentVNode("", true)], 10, _hoisted_24);
      }), 128))]), _createElementVNode("div", _hoisted_28, [_createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = $event => pre(1)),
        class: "pointer"
      }, [_withDirectives(_createElementVNode("img", {
        class: _normalizeClass(_unref(ctrl_nav_num) == 0 ? 'cant' : 'can'),
        src: _imports_6,
        alt: ""
      }, null, 2), [[_directive_lazy]])]), _createElementVNode("div", null, "0" + _toDisplayString(_unref(ctrl_nav_num) + 1), 1), _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = $event => next(1)),
        class: "pointer"
      }, [_withDirectives(_createElementVNode("img", {
        class: _normalizeClass(_unref(ctrl_nav_num) == 4 ? 'cant' : 'can'),
        src: _imports_7,
        alt: ""
      }, null, 2), [[_directive_lazy]])])])])])])) : _createCommentVNode("", true);
    };
  }
};