import { unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-dec8bf96"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sec5From"
};
import { addOnlineConsulting } from '@/api/index.js';
import { storeToRefs } from 'pinia';
import { User, Iphone, Message } from '@element-plus/icons-vue';
import { ref, reactive, toRefs, onActivated, onMounted, getCurrentInstance } from "vue";
import internationalization from '@/store/internationalization';
export default {
  __name: 'sec5Form',
  setup(__props) {
    const internationalizationStore = internationalization();
    let {
      languages
    } = storeToRefs(internationalizationStore);
    const {
      proxy,
      appContext: {
        config: {
          globalProperties
        }
      }
    } = getCurrentInstance();
    const formRef = ref(null);
    const rules = reactive({
      name: [{
        required: true,
        message: globalProperties.$t(`message.placeholderName`),
        trigger: 'blur'
      }],
      phone: [{
        required: true,
        message: globalProperties.$t(`message.placeholderPhone`),
        trigger: 'blur'
      }],
      email: [{
        required: true,
        message: globalProperties.$t(`message.placeholderEmail`),
        trigger: 'blur'
      }],
      content: [{
        required: true,
        message: globalProperties.$t(`message.placeholderContent`),
        trigger: 'blur'
      }]
    });
    const form = reactive({
      name: '',
      tel: '',
      email: '',
      content: ''
    });
    const {
      name,
      tel,
      email,
      content
    } = toRefs(form);
    const onSubmit = formEl => {
      formRef.value.validate(async valid => {
        if (valid) {
          addOnlineConsulting(form).then(res => {
            if (res) {
              proxy.$message({
                type: "success",
                message: "提交成功，稍后会有客服联系您！"
              });
              formEl.resetFields();
            }
          });
        } else {
          return false;
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        model: form,
        ref_key: "formRef",
        ref: formRef,
        inline: false,
        rules: rules
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          prop: "$t(`message.name`)"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            class: _normalizeClass(_ctx.wid),
            modelValue: form.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.name = $event),
            placeholder: _ctx.$t(`message.placeholderName`),
            "prefix-icon": _unref(User),
            clearable: ""
          }, null, 8, ["class", "modelValue", "placeholder", "prefix-icon"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          prop: _ctx.$t(`message.phone`)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            class: "wid",
            modelValue: form.phone,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.phone = $event),
            placeholder: _ctx.$t(`message.placeholderPhone`),
            "prefix-icon": _unref(Iphone),
            clearable: ""
          }, null, 8, ["modelValue", "placeholder", "prefix-icon"])]),
          _: 1
        }, 8, ["prop"]), _createVNode(_component_el_form_item, {
          prop: _ctx.$t(`message.email`)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            class: "wid",
            modelValue: form.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form.email = $event),
            placeholder: _ctx.$t(`message.placeholderEmail`),
            "prefix-icon": _unref(Message),
            clearable: ""
          }, null, 8, ["modelValue", "placeholder", "prefix-icon"])]),
          _: 1
        }, 8, ["prop"]), _createVNode(_component_el_form_item, {
          prop: _ctx.$t(`message.content`)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            class: "wid",
            rows: "4",
            modelValue: form.content,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form.content = $event),
            placeholder: _ctx.$t(`message.placeholderContent`),
            clearable: "",
            type: "textarea"
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }, 8, ["prop"]), _createVNode(_component_el_form_item, {
          label: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            class: "btn-submit",
            onClick: _cache[4] || (_cache[4] = $event => onSubmit(formRef.value))
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t(`message.confirm`)), 1)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "rules"])]);
    };
  }
};