import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    onMounted(() => {
      var html = document.getElementsByTagName("html");
      html[0].style.fontSize = document.documentElement.clientWidth / 100 + "px";
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};