import { defineStore } from 'pinia'

//defineStore 是返回一个函数 函数命名最好有use前缀，根据函数来进行下一步操作
const useInternationalizationStore = defineStore('internationalization',{
	state: () => {
        return {
            languages: "ch",
            pageIndex: 1,
            tel: '', //电话
            weChat: '', //微信
            email: '',//邮箱
            chCompanyProfile: '',//中文简介
            enCompanyProfile: '',//英文简介
            filePrefix: ''
        }
    },
    getters: {
      
    },
    actions: {
        setLanguage(languages) {
            this.languages = languages;
        },
        setPageIndex(pageIndex) {
            this.pageIndex = pageIndex;
        },
        setFilePrefix(filePrefix) {
            this.filePrefix = filePrefix;
        }
    },
    // 使用该插件，开启数据缓存
    persist: {
        enabled: true
    }
})

export default useInternationalizationStore
