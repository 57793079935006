import axios from 'axios'
import { ElMessageBox, ElMessage } from 'element-plus'
import router from "@/router";
// 创建axios实例
const service = axios.create({
  baseURL: '/adminApi', // url = base url + request url
  // withCredentials: true, // 跨域请求时发送cookies
  // headers: {
  // 'Content-Type': 'application/json;charset=UTF-8'
  // },
  timeout: 60000 // 请求超时
})

// 请求超时请求拦截器
service.interceptors.request.use(
  config => {
    // 在发出请求前做点什么
    let url = location.href;
    let useToken = '';
    if (useToken) {
      config.headers['token'] = useToken;
    }
    return config
  },
  error => {
    // 处理请求错误
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  /**
   * 如果您想要获取诸如头或状态之类的http信息
   * 请返回response=>response
   */

  /**
   * 通过自定义代码确定请求状态
   * 这里只是一个例子
   * 您还可以通过HTTP状态代码判断状态
   */
  response => {
    const res = response.data;
    //请求状态码：0成功 1失败 -1 登录过期
    if (res.code == 0 || (res.type == 'image/jpeg') || res.type == 'application/vnd.ms-excel' || res.type == 'application/json') {
      return res
    } else {
      ElMessage({
        message: res.msg || 'Error',
        type: 'error',
        duration: 3000
      });
      return Promise.reject(new Error(res.msg || 'Error'))
    }
  },
  error => {
    console.log('err' + error.message) // for debug
    ElMessage({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
